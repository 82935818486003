import React from 'react';
import { HeaderHeightProvider } from '../../context/headerHeightContext';
import { FooterHeightProvider } from '../../context/footerHeightContext';
import { MyPriceListProvider } from '../../context/myPriceListContext';
import { SeenProductsProvider } from '../../context/seenProductsContext'

export default function(props) {
  return (
    <HeaderHeightProvider>
      <FooterHeightProvider>
        <MyPriceListProvider>
          <SeenProductsProvider>
            {props.children}
          </SeenProductsProvider>
        </MyPriceListProvider>
      </FooterHeightProvider>
    </HeaderHeightProvider>
  )
}